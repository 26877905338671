import React from 'react'
import Helmet from 'react-helmet'

// import fontHeadline from 'src/styles/fonts/FuturaNowHeadline-Rg/font.woff2'
// import fontRegular from 'src/styles/fonts/FuturaNowText-Rg/font.woff2'
// import fontMedium from 'src/styles/fonts/FuturaNowText-Md/font.woff2'
// import fontLight from 'src/styles/fonts/FuturaNowText-Lt/font.woff2'

import { Props } from './types'

const siteUrl = process.env.GATSBY_SITE_URL

export const ProductSEO = ({ product, url, description }: any) => {
  if (!product) {
    return null
  }

  return (
    <Helmet>
      <script type="application/ld+json" data-react-helmet="true">
        {`
          "@context": "https://schema.org/",
          "@type": "Product",
          "name": "${product.name}",
          "image": [
            "${product.media ? product.media[0] : ''}"
          ],
          "offers": {
            "@type": "Offer",
            "url": "${siteUrl}/${url}",
            "availableAtOrFrom": {
              "@type:": "Place",
              "url":"https://www.systembolaget.se/"
            },
            "price": "${product.price}",
            "priceCurrency": "SEK"
          },
          "description": "${description}",
          "sku": "${product.sku}",
          "brand": {
            "@type": "Brand",
            "name": "${product.producerName}"
          }
      `}
      </script>
    </Helmet>
  )
}

const SEO = (props: Props): JSX.Element => {
  const { metaDescription, title, type, bodyClass, url, canonical } = props
  let { image } = props

  if (image && image) {
    if (image.startsWith('//')) {
      image = 'https:' + image
    }
  }

  const helmetProps = {
    bodyAttributes: {
      class: bodyClass || '',
    },
    htmlAttributes: {
      lang: 'sv',
    },
  }

  return (
    <Helmet defer={false} {...helmetProps}>
      {url && !canonical && <link rel="canonical" href={`${siteUrl}/${url}`} />}
      {canonical && !canonical.startsWith('http') && (
        <link rel="canonical" href={`${siteUrl}/${canonical}`} />
      )}
      {canonical && canonical.startsWith('http') && (
        <link rel="canonical" href={`${canonical}`} />
      )}
      {title && <title>{title}</title>}
      {title && <meta property="og:title" content={title} />}
      {title && <meta property="twitter:title" content={title} />}
      {title && <meta property="og:image:alt" content={title} />}
      {title && <meta property="twitter:image:alt" content={title} />}

      {/* <link rel="preload" as="font" type="font/woff2" href={fontHeadline} />
      <link rel="preload" as="font" type="font/woff2" href={fontRegular} />
      <link rel="preload" as="font" type="font/woff2" href={fontMedium} />
      <link rel="preload" as="font" type="font/woff2" href={fontLight} /> */}

      {metaDescription && <meta name="description" content={metaDescription} />}
      {metaDescription && (
        <meta name="og:description" content={metaDescription} />
      )}

      {image && <meta property="og:image" content={image} />}
      {image && <meta property="twitter:image:src" content={image} />}

      {type && <meta property="og:type" content={type} />}
      {type && <meta property="twitter:type" content={type} />}

      {/* {type && <meta property="og:url" content={url} />}
      {type && <meta property="twitter:url" content={url} />} */}
      <html lang="sv" />
    </Helmet>
  )
}

export default SEO
